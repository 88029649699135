#home {
  position: relative;
  background: url('../../assets/bgIMG.png');
  background-size: cover;
  background-repeat: repeat;
  background-position: center;

  .app_wrapper{
    padding: 0;

    .copyright {
      display:none;
    }
  }
}

.app_header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;

  padding: 6rem 2rem 0;

  @media screen and (min-width:2000px){
    padding-top: 8rem;
  }
  @media screen and (max-width:1200px){
    flex-direction: column;
  }
  @media screen and (max-width:400px){
    padding: 6rem 1rem 2rem;
  }
}

.app_header-info{
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 30%;

  @media screen and (max-width: 2000px){
    width: 50%;
    margin-right:0rem;
  }
}

.app_header-badge {
  width: 100%;
  display:flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  .badge-cmp, .tag-cmp{
    padding: 1rem 2rem;
    border: var(--white-color);
    border-radius:15px;
    flex-direction: row;
    width: auto;

    box-shadow: 0 0 20px rgba(0,0,0,0.1);
  }
  .tag-cmp{
    flex-direction: column;
    margin-top: 3rem;

    p {
      width: 100%;
      text-transform: uppercase;
      text-align: right;
    }
  }
  span {
    font-size: 2.5rem;

    @media screen and (min-width:2000px){
      font-size: 5rem;
      
    }
  }
  @media screen and (max-width:1200px){
    justify-content:flex-start;
    align-items: flex-start;
  }
}

  .app_header-circles{
    flex:0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;

    margin-left: 1rem;
    height: 100%;
    div{
      width: 100px;
      height: 100px;
      border-radius: 50%;
      
      box-shadow: 0 0 20px rgba(0,0,0, 0.1);
      background: var(--white-color);

      img{
        width: 60%;
        height: 60%;
      }
    }

    div:nth-child(1){
      width: 100px;
      height: 100px;
    }
    div:nth-child(2){
      width: 125px;
      height: 125px;
      margin: 1.75rem;
    }
    div:nth-child(3){
      width: 75px;
      height: 75px;
    }

    @media screen and (min-width: 2000px) {
      div:nth-child(1){
        width: 230px;
        height: 230px;
      }
      div:nth-child(2){
        width: 170px;
        height: 170px;
      }
      div:nth-child(3){
        width: 200px;
        height: 200px;
      }
      
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-left:0;
      flex-direction: row;
      flex-wrap: wrap;

      div{
        margin: 1rem;
      }
    }
  }
  .app_header-circles-left{
    flex:0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;

    margin-right: 1rem;
    height: 100%;
    div{
      width: 100px;
      height: 100px;
      border-radius: 50%;
      
      box-shadow: 0 0 20px rgba(0,0,0, 0.1);
      background: var(--white-color);

      img{
        width: 60%;
        height: 60%;
      }
    }

    div:nth-child(1){
      width: 100px;
      height: 100px;
    }
    div:nth-child(2){
      width: 125px;
      height: 125px;
      margin: 1.75rem;
    }
    div:nth-child(3){
      width: 75px;
      height: 75px;
    }

    @media screen and (min-width: 2000px) {
      div:nth-child(1){
        width: 230px;
        height: 230px;
      }
      div:nth-child(2){
        width: 170px;
        height: 170px;
      }
      div:nth-child(3){
        width: 200px;
        height: 200px;
      }
      
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-left:0;
      flex-direction: row;
      flex-wrap: wrap;

      div{
        margin: 1rem;
      }
    }
  }

.app_header-img{
  flex: 1;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  position: relative;

  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 1;
  }

  .overlay_circle{
    position: absolute;
    left:0;
    right:0;
    bottom: 0;
    z-index: 0;

    width: 100%;
    height: 120%;
  }

  @media screen and (max-width: 1200px){
    margin: 2rem 0;
    
  }
}